<template>
  <section class="tables">
   
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
             <div class="card-title">
              Modifier un emplacement
              <router-link to="/emplacements">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
              <form @submit.prevent="updateobjectif()">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Code <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        id="first"
                        v-model="emplacement.code"
                        required
                      />
                      <span class="text-danger" id="emplac_validation" style="display:none">Le code de l'emplacement existe déja</span>

                    </div>
                  </div>
                  <!--  col-md-6   -->

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Capacite <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="number"
                        class="form-control"
                        :min="emplacement.quantite_existante"

                        placeholder=""
                        id="first"
                        v-model="emplacement.capacite"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Quantite_existante <span class="obligatoire">*</span>
                      </label>

                      <input
                        type="number"
                        class="form-control"
                        placeholder=""
                        id="first"
                        :min="1"

                        v-model="emplacement.quantite_existante"
                        required
                      />
                    </div>
                  </div>

                  <!--  col-md-6   -->
                </div>

                <button type="submit" class="btn btn-primary">
                  + Modifier l'emplacement
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import moment from "moment";
export default {
  data() {
    return {
      emplacement: {},
      users: [],
      error: "",
    };
  },

  created() {
    this.oneoemplacement();
  },
  methods: {
    oneoemplacement() {
      HTTP
        .get("emplacements/getoneemplacement/" + this.$route.params.id)
        .then((response) => {
          this.emplacement = response.data;
          console.log("gfgggg");
        })
        .then((err) => console.log(err));
    },

    updateobjectif() {
      this.emplacement.capacite_vide =
        this.emplacement.capacite - this.emplacement.quantite_existante;

      HTTP.put("emplacements/updemplacement", this.emplacement).then(
        (response) => {
          console.log(response);
          Toast.fire({
            icon: "success",
            title: "Emplacement modifié",
          });

          this.$router.push("/emplacements");
        },
        (err) => {
          this.error = err.response.data;
          if(this.error.title=='exist'){

            
   $('#emplac_validation').show()

setTimeout(function() {
     $('#emplac_validation').fadeOut('fast');
}, 5000); 


          }
        }
      );
    },
  },
};
</script>
